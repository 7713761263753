import { Button, IconButton } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../actions";
import performRequest from "../../configAxios";
import CustomInputForm from "../../custom/CustomInput";
import { cpfNormalizer, validateDocumentIdAdmin as asyncValidate } from "../../util";
import { FormattedMessage } from "react-intl";

class FormDocId extends Component {
  state = {
    checked: true,
  };
  componentDidMount() {
    const { user, initialize } = this.props;
    initialize({ documentId: user.documentId });
  }

  validateDocument = (doc) => {
    return false;
  };

  onSubmit = (formProps) => {
    const { onActionComplete, showErrorMessage, showSuccessMessage, user } = this.props;
    performRequest("PUT", `/user/${user.id}`, { ...user, ...formProps }, true)
      .then((response) => {
        showSuccessMessage("app.update");
        onActionComplete();
      })
      .catch((e) => {
        if (e.response) {
          showErrorMessage(e.response.data.message);
        } else {
          showErrorMessage(e);
        }
      });
  };

  handleChange = (event) => {
    this.setState({ checked: event.target.checked });
  };

  render() {
    const { handleSubmit, user } = this.props;
    return (
      <Fragment>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          {user.isPj ? (
            <>
              <Field
                name="legalRepresentativeId"
                label="app.legalRepresentativeDocumentId"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
              />
              <Field
                name="legalRepresentativeName"
                label="app.legalRepresentativeName"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
              />
            </>
          ) : (
            <div color="textSecondary" style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Field
                  InputProps={{
                    readOnly: this.state.checked,
                  }}
                  name="documentId"
                  label="app.documentId"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  component={CustomInputForm}
                />
              </div>
              <div style={{ alignSelf: "center" }}>
                <IconButton onClick={(e) => this.handleChange(e)}>
                  <Edit />
                </IconButton>
              </div>
            </div>
          )}
          <Button id="bSubmitOrderForm" style={{ marginTop: 10 }} variant="contained" color="primary" size="large" type="submit">
            <FormattedMessage id="app.button.save" />
          </Button>
        </form>
      </Fragment>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  if (!values.documentId) {
    errors.documentId = "app.form.cpf.error";
  }

  if (props.user.isPj) {
    if (!values.legalRepresentativeId) {
      errors.legalRepresentativeId = "app.form.representative.cpf.error";
    }
    if (!values.legalRepresentativeName) {
      errors.legalRepresentativeName = "app.form.representative.name.error";
    }
  } else {
    if (!values.cpf) {
      errors.cpf = "app.form.cpf.error2";
    }
  }
  return errors;
};

export default compose(
  reduxForm({
    form: "formDocId",
    validate,
    asyncValidate,
    asyncBlurFields: ["documentId"],
  }),
  connect(null, actions)
)(FormDocId);
