import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Typography, withStyles } from "@material-ui/core";
import Moment from "moment";
import React, { Component } from "react";
import { CustomView, isIOS } from "react-device-detect";
import { FormattedMessage, IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import * as actions from "../actions";
import performRequest from "../configAxios";
import customInput, { CustomDateOfBirthPicker } from "../custom/CustomInput";
import customSelect from "../custom/CustomSelect";
import messages_br from "../translations/br.json";
import messages_en from "../translations/en.json";
import messages_es from "../translations/es.json";
import { docNormalizer, asyncValidateRegister as asyncValidate, cnpjNormalizer, cpfNormalizer, numberNormalizer, capitalCaseNormalizer } from "../util";
import { country } from "./components/Terms";
import cookie from "react-cookies";

function FormCheckbox({ input, label, ...props }) {
  return <Checkbox label={label} checked={input.value ? true : false} onChange={input.onChange} {...props} />;
}

const messages = {
  br: messages_br,
  en: messages_en,
  es: messages_es,
};

class Register extends Component {
  state = {
    terms: false,
    policy: false,
    termsButton: false,
    typePf: true,
    lang: "", // default em inglês caso o usuario não tenha preenchido o form ainda (inglês não tem pasta)
    locale: "en",
    changingLang: false,
  };

  typeRegister = [
    { value: "NATURAL_PERSON", input: "app.naturalPerson" },
    { value: "COMPANY", input: "app.person.legal" },
  ];

  languages = [
    { value: "en", input: "app.language.en" },
    { value: "br", input: "app.language.br" },
    { value: "es", input: "app.language.es" },
  ];

  documentTypes = {
    SELECT: { value: "", input: "app.form.selectTaxResidence" },
    STEUER_ID: { value: "STEUER_ID", input: "app.steuerId" }, //alemanha
    STEUERNUMMER_FUR_AUSLANDER: { value: "STEUERNUMMER_FUR_AUSLANDER", input: "app.steuernummerFurAuslander" }, //alemanha
    STEUERNUMMER: { value: "STEUERNUMMER", input: "app.steuernummer" }, //alemanha
    UMSATZSTEUER_IDENTIFIKATIONSNUMMER: { value: "UMSATZSTEUER_IDENTIFIKATIONSNUMMER", input: "app.umsatzsteuerIdentifikationsnummer" }, //alemanha
    CUIT: { value: "CUIT", input: "app.cuit" }, //argentina
    TFN: { value: "TFN", input: "app.tfn" }, //australia
    ABN: { value: "ABN", input: "app.abn" }, //australia
    RIJKSREGISTERNUMMER: { value: "RIJKSREGISTERNUMMER", input: "app.rijksregisternummer" }, //bélgica
    ENTREPRISE_NUMMER: { value: "ENTREPRISE_NUMMER", input: "app.entrepriseNummer" }, //bélgica
    KBO_BCE: { value: "KBO_BCE", input: "app.kboBce" }, //bélgica
    CPF: { value: "CPF", input: "app.cpf" }, //brasil
    CNPJ: { value: "CNPJ", input: "app.cnpj" }, //brasil
    RUT: { value: "RUT", input: "app.rut" }, //chile, uruguai
    NIT: { value: "NIT", input: "app.nit" }, //colombia
    NITE: { value: "NITE", input: "app.nite" }, //costa rica
    RUC: { value: "RUC", input: "app.ruc" }, //equador e panama
    SSN: { value: "SSN", input: "app.ssn" }, //eua
    EIN: { value: "EIN", input: "app.ein" }, //eua
    DNI: { value: "DNI", input: "app.dni" }, //espanha
    NIE: { value: "NIE", input: "app.nie" }, //espanha
    TIE: { value: "TIE", input: "app.tie" }, //espanha
    AFM: { value: "AFM", input: "app.afm" }, //grécia
    CNI: { value: "CNI", input: "app.cni" }, //frança
    TITRE_SEJOUR: { value: "TITRE_SEJOUR", input: "app.titreSejour" }, //frança
    SIREN: { value: "SIREN", input: "app.siren" }, //frança
    SIRET: { value: "SIRET", input: "app.siret" }, //frança
    TIN: { value: "TIN", input: "app.tin" }, //grécia, chipre
    COMPANY_REGISTRATION_NUMBER: { value: "COMPANY_REGISTRATION_NUMBER", input: "app.companyRegistrationNumber" }, //chipre
    SIN: { value: "SIN", input: "app.sin" }, //guernsey
    GCRN: { value: "GCRN", input: "app.gcrn" }, //guernsey
    TAJ: { value: "TAJ", input: "app.taj" }, //hungria
    ADOSZAM: { value: "ADOSZAM", input: "app.adoszam" }, //hungria
    CEGJEGYZEKSZAM: { value: "CEGJEGYZEKSZAM", input: "app.cegjegyzekszam" }, //hungria
    PAN: { value: "PAN", input: "app.pan" }, //india
    GSTIN: { value: "GSTIN", input: "app.gstin" }, //india
    PPS: { value: "PPS", input: "app.pps" }, //irlanda
    IRP: { value: "IRP", input: "app.irp" }, //irlanda
    CRN: { value: "CRN", input: "app.crn" }, //irlanda
    CF: { value: "CF", input: "app.cf" }, //italia
    PARTITA_IVA: { value: "PARTITA_IVA", input: "app.partitaIva" }, //italia
    NUMERO_DE_CONTRIBUABLE: { value: "NUMERO_DE_CONTRIBUABLE", input: "app.numeroDeContribuable" }, //luxemburgo
    REGISTRE_DE_COMMERCE: { value: "REGISTRE_DE_COMMERCE", input: "app.registreDeCommerce" }, //luxemburgo
    RFC: { value: "RFC", input: "app.rfc" }, //mexico
    IRD: { value: "IRD", input: "app.ird" }, //nova zelandia
    NZBN: { value: "NZBN", input: "app.nzbn" }, //nova zelandia
    BSN: { value: "BSN", input: "app.bsn" }, //paises baixos
    KVK: { value: "KVK", input: "app.kvk" }, //paises baixos
    BTW: { value: "BTW", input: "app.btw" }, //paises baixos
    NIPC: { value: "NIPC", input: "app.nipc" }, //portugal
    NIF: { value: "NIF", input: "app.nif" }, //portugal, luxemburgo, bélgica
    NIN: { value: "NIN", input: "app.nin" }, //reino unido
    BRP: { value: "BRP", input: "app.brp" }, //reino unido
    UTR: { value: "UTR", input: "app.utr" }, //reino unido
    PERSONNUMMER: { value: "PERSONNUMMER", input: "app.personnummer" }, //suécia
    SKATTEIDENTIFIKATIONSNUMMER: { value: "SKATTEIDENTIFIKATIONSNUMMER", input: "app.skatteidentifikationsnummer" }, //suécia
    UTLANDSKT_SKATTEREGISTRERINGSNUMMER: { value: "UTLANDSKT_SKATTEREGISTRERINGSNUMMER", input: "app.utlandsktSkatteregistreringsnummer" }, //suécia
    ORGANISATIONSNUMMER: { value: "ORGANISATIONSNUMMER", input: "app.organisationsnummer" }, //suécia
    UID: { value: "UID", input: "app.uid" }, //suíça
    VAT: { value: "VAT", input: "app.vat" }, //suíça
    RIF: { value: "RIF", input: "app.rif" }, //venezuela
  };

  documentTypesByTaxResidence = {
    Argentina: {
      pf: [this.documentTypes.CUIT], //Clave Única de Identificación Tributaria
      pj: [this.documentTypes.CUIT], //Clave Única de Identificación Tributaria
    },
    Austrália: {
      pf: [this.documentTypes.TFN], //Tax File Number
      pj: [this.documentTypes.ABN], //Australian Business Number
    },
    Brasil: {
      pf: [this.documentTypes.CPF],
      pj: [this.documentTypes.CNPJ],
    },
    Colômbia: {
      pf: [this.documentTypes.NIT], //Número de Identificación Tributária
      pj: [this.documentTypes.NIT], //Número de Identificación Tributária
    },
    Uruguai: {
      pf: [this.documentTypes.RUT], //Registro ùnico Tributário
      pj: [this.documentTypes.RUT], //Registro ùnico Tributário
    },
    Portugal: {
      pf: [this.documentTypes.NIF],  //Número de Identificação Fiscal
      pj: [this.documentTypes.NIPC], //Número de Identificação de Pessoa Coletiva
    },
    Chile: {
      pf: [this.documentTypes.RUT], //Rol Único Tributário
      pj: [this.documentTypes.RUT], //Rol Único Tributário
    },
    Venezuela: {
      pf: [this.documentTypes.RIF], //Registro Único de Información Fiscal
      pj: [this.documentTypes.RIF], //Registro Único de Información Fiscal
    },
    México: {
      pf: [this.documentTypes.RFC], //Registro Federal de Contribyentes
      pj: [this.documentTypes.RFC], //Registro Federal de Contribyentes
    },
    Equador: {
      pf: [this.documentTypes.RUC], //Registro Único de Contribuyentes
      pj: [this.documentTypes.RUC], //Registro Único de Contribuyentes
    },
    Índia: {
      pf: [this.documentTypes.PAN], //Permanent Account Numbe
      pj: [this.documentTypes.GSTIN], //Goods and Services Tax Identification Number
    },
    ["Nova Zelândia"]: {
      pf: [this.documentTypes.IRD], //Inland Revenue Department Number
      pj: [this.documentTypes.NZBN], //New Zealand Business Number
    },
    Panamá: {
      pf: [this.documentTypes.RUC], //Registro Único de Contribuyente
      pj: [this.documentTypes.RUC], //Registro Único de Contribuyente
    },
    EUA: {
      pf: [this.documentTypes.SSN], // Social Security Number
      pj: [this.documentTypes.EIN], //Employer Identification Number
    },
    ["Costa Rica"]: { 
      pf: [this.documentTypes.NITE], //Número de Identificación Tributária Especia
      pj: [this.documentTypes.NITE], //Número de Identificación Tributária Especia
    },
    Guernsey: {
      pf: [this.documentTypes.SIN], //Social Insurance Number
      pj: [this.documentTypes.GCRN], //Guernsey Company Registration Number
    },
    Espanha: {
      pf: [
        this.documentTypes.DNI, //Documento Nacional de Identidade
        this.documentTypes.NIE, //Número de Identificação de Estrangeiro
        this.documentTypes.TIE, //Cartão de Identificação de Estrangeiro residentes há mais de seis meses na Espanha
      ],
      pj: [this.documentTypes.NIF], //Número de Identficação Fiscal
    },
    França: {
      pf: [
        this.documentTypes.CNI, //Carteira de Identidade Francesa
        this.documentTypes.TITRE_SEJOUR, //Autorização de residência
      ],
      pj: [
        this.documentTypes.SIREN, //Sistema de Identificação de Diretório Empresarial
        this.documentTypes.SIRET, //Sistema de Identificação de Diretórios de Estabelecimento (SIREN + 5 dígitos)
      ],
    },
    ["Reino Unido"]: {
      pf: [
        this.documentTypes.NIN, //Número do Seguro Nacional
        this.documentTypes.BRP, //Autorização de Residência Biométrica
      ],
      pj: [this.documentTypes.UTR], //Referência Única do Contribuinte
    },
    Irlanda: {
      pf: [
        this.documentTypes.PPS, //Número de serviço público pessoal
        this.documentTypes.IRP, //Autorização de residência irlandesa
      ],
      pj: [this.documentTypes.CRN], //Número de Registro de Empresa
    },
    Suíça: {
      pf: [this.documentTypes.SSN], //Número Social Versicherung
      pj: [
        this.documentTypes.UID, //Número de identificação unternehmens
        this.documentTypes.VAT, //Número de verificação de valor
      ],
    },
    Itália: {
      pf: [this.documentTypes.CF], //Código de Identificação Fiscal
      pj: [this.documentTypes.PARTITA_IVA], //Número de identificação do Partita IVA na Itália
    },
    Alemanha: {
      pf: [
        this.documentTypes.STEUER_ID, //Número de Identificação Fiscal
        this.documentTypes.STEUERNUMMER_FUR_AUSLANDER, //Número de Imposto para Estrangeiros
      ],
      pj: [
        this.documentTypes.STEUERNUMMER, //Número de Imposto
        this.documentTypes.UMSATZSTEUER_IDENTIFIKATIONSNUMMER, //Umsatzsteuer-Identifikationsnummer
      ],
    },
    Grécia: {
      pf: [this.documentTypes.AFM], //Número de Registro Fiscal
      pj: [this.documentTypes.TIN], //Número de Identificação Fiscal Grego
    },
    ["Países Baixos"]: {
      pf: [this.documentTypes.BSN], //Número de Identificação de Cidadão
      pj: [
        this.documentTypes.KVK, //Número de Registro da Câmara de Comércio
        this.documentTypes.BTW, //Número de Identificação de Imposto sobre o Valor Agregado
      ],
    },
    Bélgica: {
      pf: [
        this.documentTypes.RIJKSREGISTERNUMMER, //Número de Registro Nacional
        this.documentTypes.NIF, //Número de Identificação Fiscal
      ],
      pj: [
        this.documentTypes.ENTREPRISE_NUMMER, //Número de Empresa
        this.documentTypes.KBO_BCE, //Número de Registro da Empresa
      ],
    },
    Hungria: {
      pf: [this.documentTypes.TAJ], //Número de Identificação Fiscal
      pj: [
        this.documentTypes.ADOSZAM, //Número de Imposto
        this.documentTypes.CEGJEGYZEKSZAM], //Número de Registro da Empresa
      },
    Chipre: {
      pf: [this.documentTypes.TIN], //Número de Identificação Fiscal
      pj: [
        this.documentTypes.TIN, //Número de Identificação Fiscal
        this.documentTypes.COMPANY_REGISTRATION_NUMBER, //Número de Registro de Empresa
      ],
    },
    Luxemburgo: {
      pf: [
        this.documentTypes.NIF, //Número de Identificação Fiscal - 
        this.documentTypes.NUMERO_DE_CONTRIBUABLE, //Número de Registro de Contribuinte
      ],
      pj: [
        this.documentTypes.NIF, //Número de Identificação Fiscal
        this.documentTypes.REGISTRE_DE_COMMERCE //Numéro d'Enregistrement au Registre de Commerce (Número de Registro de Empresa)

      ], 
    },
    Suécia: {
      pf: [
        this.documentTypes.PERSONNUMMER, //Número de Identificação Pessoal
        this.documentTypes.SKATTEIDENTIFIKATIONSNUMMER, //Número de Identificação Fiscal
        this.documentTypes.UTLANDSKT_SKATTEREGISTRERINGSNUMMER, //Número de Registro de Estrangeiro
      ], 
      pj: [
        this.documentTypes.ORGANISATIONSNUMMER, //Número de Registro de Empresa
        this.documentTypes.SKATTEIDENTIFIKATIONSNUMMER,//Número de Identificação Fiscal
      ],
    },
  };

  normalizersByDocumentType = {
    CPF: cpfNormalizer,
    CNPJ: cnpjNormalizer,
    CUIT: numberNormalizer,
    TFN: numberNormalizer,
    ABN: numberNormalizer,
    RUT: numberNormalizer,
    NIT: numberNormalizer,
    NITE: numberNormalizer,
    SSN: numberNormalizer,
    EIN: numberNormalizer,
    RUC: numberNormalizer,
    SIN: numberNormalizer,
    GCRN: numberNormalizer,
    PAN: numberNormalizer,
    GSTIN: numberNormalizer,
    RFC: numberNormalizer,
    IRD: numberNormalizer,
    NZBN: numberNormalizer,
    NIF: numberNormalizer,
    NIPC: numberNormalizer,
    RIF: numberNormalizer,
    DNI: docNormalizer,
    NIE: docNormalizer,
    TIE: docNormalizer,
    CNI: docNormalizer,
    TITRE_SEJOUR: docNormalizer,
    SIREN: docNormalizer,
    SIRET: docNormalizer,
    NIN: docNormalizer,
    BRP: docNormalizer,
    UTR: docNormalizer,
    PPS: docNormalizer,
    IRP: docNormalizer,
    CRN: docNormalizer,
    UID: docNormalizer,
    VAT: docNormalizer,
    CF: docNormalizer,
    PARTITA_IVA: docNormalizer,
    STEUER_ID: docNormalizer,
    STEUERNUMMER_FUR_AUSLANDER: docNormalizer,
    STEUERNUMMER: docNormalizer,
    UMSATZSTEUER_IDENTIFIKATIONSNUMMER: docNormalizer,
    AFM: docNormalizer,
    TIN: docNormalizer,
    BSN: docNormalizer,
    KVK: docNormalizer,
    BTW: docNormalizer,
    RIJKSREGISTERNUMMER: docNormalizer,
    ENTREPRISE_NUMMER: docNormalizer,
    KBO_BCE: docNormalizer,
    TAJ: docNormalizer,
    ADOSZAM: docNormalizer,
    CEGJEGYZEKSZAM: docNormalizer,
    COMPANY_REGISTRATION_NUMBER: docNormalizer,
    NUMERO_DE_CONTRIBUABLE: docNormalizer,
    REGISTRE_DE_COMMERCE: docNormalizer,
    PERSONNUMMER: docNormalizer,
    SKATTEIDENTIFIKATIONSNUMMER: docNormalizer,
    UTLANDSKT_SKATTEREGISTRERINGSNUMMER: docNormalizer,
    ORGANISATIONSNUMMER: docNormalizer,
  };

  onSubmit = (formProps) => {
    this.setState({ formProps, terms: true });
  };

  accept = () => {
    const { formProps, recaptcha } = this.state;
    const { showSuccessMessage, showErrorMessage, history } = this.props;
    if (formProps.name) { formProps.name = capitalCaseNormalizer(formProps.name); }
    const params = { ...formProps, recaptcha };
    performRequest("POST", "/user/register", params)
      .then((response) => {
        showSuccessMessage("app.register.success");
        history.push("/");
      })
      .catch((e) => {
        if (e.response) {
          showErrorMessage(e.response.data.message);
        } else {
          showErrorMessage(e);
        }
      });
  };

  validateTerms = (value) => (value ? undefined : "app.termsAndConditions");

  onChangeType = (type) => {
    this.props.reset();
    this.props.change("locale", this.state.locale);
    this.setState({ typePf: type == "NATURAL_PERSON" ? true : false, type });
  };

  verifyCallback = (recaptchaToken) => {
    this.setState({ recaptcha: recaptchaToken });
  };

  onChangeLengageTerm(locale) {
    // en, br, es
    this.setState({ changingLang: true });
    if (locale == "br") {
      this.setState({ lang: "/pt", locale });
    } else if (locale == "es") {
      this.setState({ lang: "/es", locale });
    } else {
      this.setState({ lang: "", locale });
    }
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);
    cookie.save("lclocale", locale, { path: "/", expires });
    setTimeout(() => {
      this.props.initialize({ locale });
      this.setState({ changingLang: false });
    }, 1);
  }

  render() {
    const { handleSubmit, pristine, invalid, submitting, taxResidence, documentType } = this.props;
    const { typePf, locale, changingLang } = this.state;
    const personType = typePf ? "pf" : "pj";
    const documents = taxResidence == null ? [this.documentTypes.SELECT] : this.documentTypesByTaxResidence[taxResidence][personType];
    const documentNormalizer = this.normalizersByDocumentType[documentType];
    return (
      <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: 500,
            padding: 16,
          }}
        >
          <div style={{ textAlign: "center", marginTop: 48, marginBottom: 32 }}>
            <img src={process.env.PUBLIC_URL + "/logo/Logo_LC_BANK.png"} alt="logo" width="200" height="200" />
          </div>
          <Typography variant="h5" gutterBottom color="primary" style={{ textAlign: "center" }}>
            Onboarding
          </Typography>
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <Field name="locale" label="app.language" component={customSelect} values={this.languages} InputLabelProps={{ shrink: true }} onChange={(e) => this.onChangeLengageTerm(e.target.value)} />
            <Field name="type" label="app.personType" component={customSelect} values={this.typeRegister} InputLabelProps={{ shrink: true }} onChange={(e) => this.onChangeType(e.target.value)} />
            <Field name="name" label="app.name" component={customInput} InputLabelProps={{ shrink: true }}/>
            <Field name="taxResidence" label="app.taxResidence" component={customSelect} values={country} InputLabelProps={{ shrink: true }} />
            <Field name="documentType" label="app.documentType" component={customSelect} values={documents} InputLabelProps={{ shrink: true }} />
            <Field name="documentId" label="app.documentIdTax" component={customInput} InputLabelProps={{ shrink: true }} normalize={documentNormalizer} />
            {typePf && (
              <>
                <CustomView condition={isIOS}>
                  <Field name="dateOfBirth" label="app.dateOfBirth" component={customInput} InputLabelProps={{ shrink: true }} type="date" />
                </CustomView>
                <CustomView condition={!isIOS}>
                  <Field name="dateOfBirth" label="app.dateOfBirth" component={CustomDateOfBirthPicker} InputLabelProps={{ shrink: true }} />
                </CustomView>
              </>
            )}
            <Field name="email" label="app.email" component={customInput} InputLabelProps={{ shrink: true }} />
            <Field name="confirmEmail" label="app.confirmEmail" component={customInput} InputLabelProps={{ shrink: true }} />
            <Field name="password" label="app.password" type="password" component={customInput} inputProps={{ maxLength: 20 }} InputLabelProps={{ shrink: true }} />
            <Field name="confirmPassword" label="app.confirmPassword" type="password" component={customInput} inputProps={{ maxLength: 20 }} InputLabelProps={{ shrink: true }} />
            <Field name="agentId" label="app.agentId" component={customInput} InputLabelProps={{ shrink: true }} normalize={numberNormalizer} />
            <Typography gutterBottom variant="caption" color="primary">
              <FormattedMessage id="app.register.text" />{" "}
              <a style={{ color: "#FEC12C" }} href={"https://lux.capital/" + this.state.lang + "/be-a-client"}>
                <FormattedMessage id="app.clickHere" />
              </a>
            </Typography>
            <Button color="primary" variant="contained" type="submit" fullWidth style={{ marginTop: 16 }} disabled={pristine || invalid || submitting || changingLang}>
              <FormattedMessage id="app.signup" />
            </Button>
          </form>
          <Dialog open={this.state.terms} variant="secondary" onClose={(e) => this.setState({ terms: false })}>
            <DialogTitle
              style={{
                color: "black",
                backgroundColor: "rgba(255, 190, 45, 1)",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <FormattedMessage id="app.confirmTerms" />
              </div>
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "justify",
                }}
              >
                <Button fullWidth size="small" variant="text" style={{ marginTop: 16 }} component={Button} href={`https://lux.capital${this.state.lang}/terms`} target="_blank">
                  <div style={{ color: "#FEC12C" }}>
                    <FormattedMessage id="app.termsAndConditions.link" />
                  </div>
                </Button>
                <FormControlLabel
                  label={<FormattedMessage id="app.withdrawTerms" />}
                  control={<Field component={FormCheckbox} name="termsAndConditions" color="primary" validate={this.validateTerms} />}
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 16, marginBottom: 16 }}
                  disabled={pristine || invalid || submitting || changingLang}
                  onClick={(e) => this.accept()}
                >
                  <FormattedMessage id="app.signup" />
                </Button>
              </div>
            </DialogContent>
          </Dialog>
          <div style={{ marginTop: 32 }}>
            <Button fullWidth size="small" variant="text" style={{ marginTop: 16 }} component={Button} href={`https://lux.capital${this.state.lang}/terms`} target="_blank">
              <FormattedMessage id="app.termsAndConditions" />
            </Button>
            <Button fullWidth size="small" variant="text" style={{ marginTop: 16 }} component={Button} href={`https://lux.capital${this.state.lang}/privacy`} target="_blank">
              <FormattedMessage id="app.privacyPolicy" />
            </Button>

            {/* <FormDialog
            title="app.termsAndConditions"
            open={this.state.termsButton}
            variant="secondary"
            noHeader
            onClose={(e) => this.setState({ termsButton: false })}
          >
            <Typography component="div">
							<Terms />
						</Typography>
          </FormDialog>
          <FormDialog
            title="app.privacyPolicy"
            open={this.state.policy}
            variant="secondary"
            noHeader
            onClose={(e) => this.setState({ policy: false })}
          >
            <Typography component="div">
              <Policy />
            </Typography>
          </FormDialog> */}
          </div>
        </div>
      </IntlProvider>
    );
  }
}

const styles = (theme) => ({
  cardHeader: {
    textAlign: "center",
  },
  card: {
    marginTop: "100px",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
});

const validate = (values, props) => {
  const errors = {};
  if (!props.anyTouched) return errors;
  if (!values.name) {
    errors.name = "app.form.name.error";
  } else if (values.name.trim().length == 0) {
    errors.name = "app.name.invalid";
  }
  if (!values.locale) {
    errors.locale = "app.form.locale.error";
  }
  if (!values.taxResidence) {
    errors.taxResidence = "app.form.taxResidence.error";
  }
  if (!values.documentType) {
    errors.documentType = "app.form.documentType.error";
  } else if (values.documentType.trim().length == 0) {
    errors.documentType = "app.form.documentType.error";
  }
  if (!values.documentId) {
    errors.documentId = "app.form.documentId.error";
  } else if (values.documentId.trim().length == 0) {
    errors.documentId = "app.documentId.invalid";
  }
  if (!values.dateOfBirth) {
    errors.dateOfBirth = "app.form.dateOfBirth.error";
  } else {
    const inputDate = Moment(values.dateOfBirth);
    const today = Moment();
    const diffYears = today.diff(inputDate, "years");
    if (diffYears < 18) {
      errors.dateOfBirth = "app.form.dateOfBirth.min";
    }
  }
  if (!values.email) {
    errors.email = "app.form.email.error";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+$/i.test(values.email.trim())) {
    errors.email = "app.form.email.invalid";
  } else {
    if (!values.confirmEmail) {
      errors.confirmEmail = "app.form.confirmEmail.error";
    } else if (values.email !== values.confirmEmail) {
      errors.confirmEmail = "app.form.confirmEmail.error.equals";
    }
  }
  if (!values.password) {
    errors.password = "app.password.error";
  } else if (values.password.length < 6) {
    errors.password = "app.password.min";
  } else {
    if (!values.confirmPassword) {
      errors.confirmPassword = "app.confirmPassword.error";
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "app.confirmPassword.error.equals";
    }
  }
  if (!values.agentId) {
    errors.agentId = "app.form.agentId.error";
  }
  return errors;
};

const selector = formValueSelector("registerForm");

function mapStateToProps(state) {
  return {
    initialValues: {
      locale: "en",
    },
    auth: state.auth.authenticated,
    currentUser: state.user.user.me,
    taxResidence: selector(state, "taxResidence"),
    documentType: selector(state, "documentType"),
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, actions),
  reduxForm({
    form: "registerForm",
    enableReinitialize: false,
    keepDirtyOnReinitialize: false,
    asyncValidate,
    asyncBlurFields: ["email", "documentId", "taxResidence", "agentId"],
    asyncChangeFields: ["agentId"],
    validate,
    touchOnChange: true,
  })
)(Register);
